import type { Cart, Payment } from "@commercetools/platform-sdk";
import type { Reducer } from "react";
import LocalStorage from "../../utils/local-storage";
import type { CheckoutContextState } from "./context";

type SetCartAction = { type: "setCart"; cart?: Cart };
type SetCartLoadingAction = {
	type: "setCartLoading";
	loading: boolean;
};
type AdyenLoadingAction = {
	type: "setAdyenLoading";
	loading: boolean;
};
type PaymentInProgressAction = {
	type: "setPaymentInProgress";
	inProgress: boolean;
};
type ActiveAdyenPaymentAction = {
	type: "setActiveAdyenPayment";
	payment?: Payment;
};

type ShowAddressFormAction = {
	type: "setShowAddressForm";
	showAddressForm: boolean;
};

type PaymentIsDone = {
	type: "setPaymentIsDone";
	paymentIsDone: boolean;
};

type PaymentErrorAction = {
	type: "setPaymentError";
	paymentError?: string;
};

type ResetPaymentAction = {
	type: "resetPayment";
};

export type CheckoutContextAction =
	| SetCartAction
	| SetCartLoadingAction
	| ActiveAdyenPaymentAction
	| AdyenLoadingAction
	| PaymentInProgressAction
	| ShowAddressFormAction
	| PaymentIsDone
	| ResetPaymentAction
	| PaymentErrorAction;

export const checkoutReducer: Reducer<
	CheckoutContextState,
	CheckoutContextAction
> = (state, action) => {
	switch (action.type) {
		case "resetPayment": {
			return {
				...state,
				paymentError: undefined,
				paymentIsDone: undefined,
				paymentInProgress: false,
				activeAdyenPayment: undefined,
				adyenLoading: false,
				cart: undefined,
				showAddressForm: true,
			};
		}
		case "setPaymentError": {
			return {
				...state,
				paymentError: action.paymentError,
				paymentIsDone: false,
				paymentInProgress: false,
			};
		}
		case "setPaymentIsDone": {
			return {
				...state,
				paymentIsDone: action.paymentIsDone,
				paymentError: undefined,
			};
		}
		case "setCart": {
			if (
				action.cart?.id === state.cart?.id &&
				action.cart?.version === state.cart?.version
			)
				return state;

			if (action.cart) {
				LocalStorage.set("CT_CART_ID", action.cart.id);
			} else {
				LocalStorage.remove("CT_CART_ID");
			}

			return {
				...state,
				cart: action.cart,
			};
		}
		case "setShowAddressForm": {
			return {
				...state,
				showAddressForm: action.showAddressForm,
			};
		}
		case "setCartLoading": {
			return {
				...state,
				cartLoading: action.loading,
			};
		}
		case "setActiveAdyenPayment": {
			if (
				action.payment?.id === state.activeAdyenPayment?.id &&
				action.payment?.version === state.activeAdyenPayment?.version
			)
				return state;

			return {
				...state,
				activeAdyenPayment: action.payment,
			};
		}
		case "setAdyenLoading": {
			return {
				...state,
				adyenLoading: action.loading,
			};
		}
		case "setPaymentInProgress": {
			return {
				...state,
				paymentInProgress: action.inProgress,
			};
		}
		default: {
			console.warn("Unhandled action type", action);
			return state;
		}
	}
};
