import * as Cookies from "es-cookie";
import { hasCookieConsent, type CookieConsent } from "./cookie-consent-service";

type SetCookieProps = {
	name: string;
	value: string;
	requireConsent: CookieConsent;
	attributes?: Cookies.CookieAttributes;
};

declare global {
	interface Window {
		CookieScript?: {
			instance: {
				currentState: () => {
					categories: string[];
				};
				show: () => void;
			};
		};
	}
}

export const setCookie = (cookie: SetCookieProps) => {
	const { name, value, requireConsent, attributes } = cookie;

	if (requireConsent === "strict") {
		Cookies.set(name, value, attributes);
	} else if (hasCookieConsent(requireConsent)) {
		Cookies.set(name, value, attributes);
	} else {
		window.addEventListener(`CookieScriptCategory-${requireConsent}`, () => {
			Cookies.set(name, value, attributes);
		});
	}
	window.addEventListener(`CookieScriptCategory-${requireConsent}`, () => {
		cleanUpCookie(name, requireConsent);
	});
};

/**
 *
 * @param name The name of the cookie to fetch
 * @returns JSON object with the key and value of the cookie
 */
export const getCookie = (name: string): string | undefined => {
	if (typeof document === "undefined") return undefined;
	return Cookies.get(name);
};

export const hasCookie = (name: string): boolean =>
	getCookie(name) !== undefined;

export const removeCookie = (name: string): void => Cookies.remove(name);

export const cleanUpCookie = (name: string, consent: CookieConsent) => {
	if (hasCookie(name)) {
		const cookieScriptCloseListener = () => {
			if (
				!window.CookieScript?.instance
					.currentState()
					.categories.includes(consent)
			) {
				Cookies.remove(name);
				window.removeEventListener(
					"CookieScriptClose",
					cookieScriptCloseListener,
				);
			}
		};
		window.addEventListener("CookieScriptClose", cookieScriptCloseListener);
	}
};

export const openCookieSettings = () => {
	if (window.CookieScript) window.CookieScript.instance.show();
};
