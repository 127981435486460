import type { TCountryCode } from "countries-list";
import type { ScriptProps } from "gatsby";
import type { Reducer } from "react";
import { setCountryCookie } from "../../utils/country";
import type { SiteConfig, State } from "./context";
import type { ProductProjection } from "@commercetools/platform-sdk";

type SiteConfigAction = {
	type: "setSiteConfig";
	siteConfig: SiteConfig;
};
type AddScriptAction = {
	type: "addScript";
	script: ScriptProps;
};
type RemoveScriptAction = {
	type: "removeScript";
	script: ScriptProps;
};

type HideNoticeBar = {
	type: "hideNoticeBar";
	hideNoticeBar: boolean;
};

type SetToasterProduct = {
	type: "setToasterProduct";
	toasterProduct:
		| {
				product: Queries.CommerceToolsProductFragment;
				variant: Queries.ProductVariantFragment;
		  }
		| undefined;
};

type SetUserCountry = {
	type: "setUserCountry";
	userCountry: TCountryCode;
};

type SetSearchOpen = {
	type: "setSearchOpen";
	searchOpen: boolean;
};

type SetNavOpen = {
	type: "setNavOpen";
	navOpen: boolean;
};

type SetDrawerIndices = {
	type: "setDrawerIndices";
	drawerIndices: (number | null | undefined)[];
};

type SetGiftBoxProduct = {
	type: "setGiftBoxProduct";
	product: ProductProjection;
};

type SetGiftMessageProduct = {
	type: "setGiftMessageProduct";
	product: ProductProjection;
};

type SetAwinId = {
	type: "setAwinId";
	awinId: string | undefined;
};

type SetCjEventId = {
	type: "setCjEventId";
	cjEventId: string | undefined;
};

export type ActiveSearchType = "page" | "product";
type SetActiveSearchType = {
	type: "setActiveSearchType";
	activeSearchType: ActiveSearchType;
};

export type Action =
	| SiteConfigAction
	| AddScriptAction
	| RemoveScriptAction
	| HideNoticeBar
	| SetUserCountry
	| SetSearchOpen
	| SetNavOpen
	| SetDrawerIndices
	| SetActiveSearchType
	| SetGiftBoxProduct
	| SetGiftMessageProduct
	| SetToasterProduct
	| SetAwinId
	| SetCjEventId;

export const appReducer: Reducer<State, Action> = (state, action) => {
	switch (action.type) {
		case "setSiteConfig": {
			return {
				...state,
				siteConfig: action.siteConfig,
			};
		}
		case "addScript": {
			const scriptList = [...state.scripts];
			let existingScriptPos: number | undefined;
			if (action.script.id) {
				existingScriptPos = scriptList.findIndex(
					(script) => script.id === action.script.id,
				);
			}

			if (existingScriptPos !== undefined && existingScriptPos !== -1) {
				scriptList[existingScriptPos] = action.script;
			} else {
				scriptList.push(action.script);
			}

			return {
				...state,
				scripts: scriptList,
			};
		}
		case "removeScript": {
			return {
				...state,
				scripts: state.scripts.filter(
					(existingScript) =>
						!existingScript.id || existingScript.id !== action.script.id,
				),
			};
		}
		case "hideNoticeBar": {
			return {
				...state,
				hideNoticeBar: action.hideNoticeBar,
			};
		}
		case "setGiftMessageProduct": {
			return {
				...state,
				giftMessageProduct: action.product,
			};
		}
		case "setGiftBoxProduct": {
			return {
				...state,
				giftBoxProduct: action.product,
			};
		}
		case "setToasterProduct": {
			return {
				...state,
				toasterProduct: action.toasterProduct,
			};
		}
		case "setAwinId": {
			return {
				...state,
				awinId: action.awinId,
			};
		}
		case "setCjEventId": {
			return {
				...state,
				cjEventId: action.cjEventId,
			};
		}
		case "setUserCountry": {
			setCountryCookie(action.userCountry);
			return {
				...state,
				userCountry: action.userCountry,
			};
		}
		case "setSearchOpen": {
			return {
				...state,
				searchOpen: action.searchOpen,
				drawerIndices: [null, null],
				navOpen: false,
			};
		}
		case "setNavOpen": {
			return {
				...state,
				navOpen: action.navOpen,
				searchOpen: false,
			};
		}
		case "setDrawerIndices": {
			return {
				...state,
				drawerIndices: action.drawerIndices,
				searchOpen: false,
			};
		}
		case "setActiveSearchType": {
			return {
				...state,
				activeSearchType: action.activeSearchType,
			};
		}
		default: {
			return state;
		}
	}
};
