import is from "@sindresorhus/is";
import { useEffect, type FC, type PropsWithChildren } from "react";
import { useErrorBoundary } from "react-use-error-boundary";

// File inspired and adapted from https://github.com/gatsbyjs/gatsby/issues/33956#issuecomment-977854027

export const CoreErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
	const [error] = useErrorBoundary();
	const errorName = is.error(error) ? error.name : undefined;
	const errorMessage = is.error(error) ? error.message : undefined;

	useEffect(() => {
		const originalConsoleError = console.error;

		console.error = (...args: unknown[]) => {
			if (typeof args[0] === "string" && /defaultProps/.test(args[0])) {
				return;
			}

			originalConsoleError(...args);
		};

		return () => {
			console.error = originalConsoleError;
		};
	}, []);

	if (is.error(error)) {
		if (
			errorName === "ChunkLoadError" ||
			errorMessage?.startsWith(
				"The result of this StaticQuery could not be fetched",
			) ||
			errorMessage?.match(/We couldn't load "\/page-data\/sq\/d\/\d+\.json"/)
		) {
			const currentUrl = new URL(window.location.href);
			const paramName = "reloaded";
			if (currentUrl.searchParams.get(paramName) === "1") {
				throw error;
			} else {
				currentUrl.searchParams.set(paramName, "1");
				window.location.href = currentUrl.href;
			}
		} else {
			console.error("An error occurred", error);
			throw error;
		}
	} else if (error) {
		console.error(error);
		throw new Error("Unknown error");
	}

	return children;
};
