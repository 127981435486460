import { isBrowser } from "./browser-utils";
import { getCookie, removeCookie, setCookie } from "./cookies/cookie-service";

const nodeStore: { [key: string]: string } = {};

const LocalStorage = {
	get: (key: string): string | undefined => {
		if (!isBrowser()) return nodeStore[key];

		let value: string | undefined = undefined;
		try {
			value = localStorage.getItem(key) ?? undefined;
		} catch {
			value = getCookie(key);
		}

		return value;
	},
	set: (key: string, value: string | number | boolean | void): void => {
		if (value === undefined) return;

		if (!isBrowser()) {
			nodeStore[key] = String(value);
			return;
		}

		try {
			localStorage.setItem(key, String(value));
		} catch {
			setCookie({
				name: key,
				value: String(value),
				requireConsent: "strict",
				attributes: { expires: 365 },
			});
		}
	},
	remove: (key: string): void => {
		if (!isBrowser()) {
			delete nodeStore[key];
			return;
		}

		try {
			localStorage.removeItem(key);
		} catch {
			removeCookie(key);
		}
	},
};

export default LocalStorage;
